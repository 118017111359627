import React, { useState, useEffect } from 'react'
import { useSnackbar } from '@flock/shared-ui'
import { useCheckout } from '@stripe/react-stripe-js'
import {
  useTheme,
  useMediaQuery,
  InputProps,
  SxProps,
  Theme,
} from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import { InspectionPaymentCheckoutFormGetSalesforceAccountDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatIntegerCents, formatPercentage } from '@flock/utils'
import { InspectionPaymentCheckoutFormProps } from './inspectionPaymentCheckoutFormTypes'

export const GET_SALESFORCE_ACCOUNT = gql`
  query InspectionPaymentCheckoutFormGetSalesforceAccount(
    $input: Core_GetSalesforceAccountRequestInput!
  ) {
    getSalesforceAccount(input: $input) {
      salesforceAccount {
        fullName
        valuationPageNameOverride
        owner {
          firstName
        }
      }
    }
  }
`

const useInspectionPaymentCheckoutForm = (
  props: InspectionPaymentCheckoutFormProps
) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { notify } = useSnackbar()
  const [subtotal, setSubtotal] = useState('')
  const [taxAmount, setTaxAmount] = useState('')
  const [total, setTotal] = useState('')
  const [taxPercent, setTaxPercent] = useState('') // string representing percent e.g. 8.2%
  const [showTax, setShowTax] = useState(true)

  const { accountId } = props
  const { data: accountData } = useQuery(
    InspectionPaymentCheckoutFormGetSalesforceAccountDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
    }
  )

  let firstName =
    accountData?.getSalesforceAccount?.salesforceAccount?.fullName?.split(
      ' '
    )[0] || ''
  if (
    accountData?.getSalesforceAccount?.salesforceAccount
      ?.valuationPageNameOverride
  ) {
    firstName =
      accountData?.getSalesforceAccount?.salesforceAccount
        ?.valuationPageNameOverride
  }

  const checkout = useCheckout()

  useEffect(() => {
    if (checkout.lineItems.length > 0) {
      const item = checkout.lineItems[0]
      setSubtotal(formatIntegerCents(item.amountSubtotal))
      setTaxAmount(formatIntegerCents(item.amountTaxExclusive)) // we are using exclusive tax setting
      setTotal(
        formatIntegerCents(item.amountSubtotal + item.amountTaxExclusive)
      )
      setTaxPercent(
        formatPercentage(item.amountTaxExclusive / item.amountSubtotal, 2)
      )
      setShowTax(item.amountTaxExclusive > 0)
    }
  }, [checkout])

  // assumption here is that there is only one line item (the inspection payment) so take the first one
  const description = checkout.lineItems[0].description || ''

  const [email, setEmail] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')

  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('')

  const handleButtonClick = async () => {
    setPaymentLoading(true)
    setEmailErrorMessage('')
    setPaymentErrorMessage('')
    if (!email) {
      setPaymentErrorMessage('Please enter your email address')
      setEmailErrorMessage('Please enter your email address')
      setPaymentLoading(false)
      return
    }
    try {
      const result = await checkout.confirm()
      if (result.type === 'error') {
        setPaymentErrorMessage(result.error.message)
      }
      setPaymentLoading(false)
    } catch (e) {
      setPaymentLoading(false)
      notify('An error occurred with your payment. Please try again', 'error')
    }
  }

  const emailHandleBlur = () => {
    checkout.updateEmail(email).then((result) => {
      if (result.type === 'error') {
        setEmailErrorMessage(result.error.message)
      }
    })
  }

  const emailHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailErrorMessage('')
    setEmail(e.target.value)
  }

  const emailInputProps: Partial<InputProps> = {
    disableUnderline: true,
    sx: {
      '&.MuiInputBase-root': {
        marginTop: '4px',
        border: '1px solid rgb(223, 226, 230)',
      },
      '& .MuiInputBase-input': {
        padding: '12px',
      },
      // copied from https://mui.com/joy-ui/react-input/#focused-ring
      '--Input-focusedInset': 'var(--any, )',
      '--Input-focusedThickness': '0.25rem',
      '--Input-focusedHighlight': 'rgba(13,110,253,.25)',
      '&::before': {
        transition: 'box-shadow .15s ease-in-out',
      },
      '&:focus-within': {
        borderColor: '#86b7fe',
        borderWidth: '3px',
      },
    },
  }

  const emailSx: SxProps<Theme> = {
    '& .MuiInputLabel-shrink': {
      transform: 'none', // remove default transform specified in theme
      color: 'rgb(48, 49, 61)', // matching stripe element label color
      fontSize: '14.88px',
      fontWeight: 300,
    },
    '& .MuiInputBase-root.Mui-focused': {
      boxShadow: 'none',
    },
    '& .Mui-error.MuiInputLabel-shrink': {
      color: 'rgb(48, 49, 61)', // stripe element labels don't change color during error
    },
    '& .Mui-error': {
      color: '#df1b41',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '0px',
      color: '#df1b41',
    },
  }

  return {
    description,
    subtotal,
    taxAmount,
    taxPercent,
    total,
    showTax,
    isMobile,
    isTablet,
    firstName,
    emailHandleBlur,
    emailHandleChange,
    emailErrorMessage,
    handleButtonClick,
    paymentLoading,
    paymentErrorMessage,
    emailInputProps,
    emailSx,
    reaName:
      accountData?.getSalesforceAccount?.salesforceAccount?.owner?.firstName ||
      '',
  }
}

export default useInspectionPaymentCheckoutForm

import React from 'react'
import { PaymentElement, AddressElement } from '@stripe/react-stripe-js'
import { Box, Typography, Button } from '@mui/material'
import { FormattedTextField } from '@flock/shared-ui'

import { InspectionPaymentCheckoutFormViewProps } from './inspectionPaymentCheckoutFormTypes'
import InspectionWorkerGraphic from '../../../../images/inspection-cover-photo-desktop.webp'

const InspectionPaymentCheckoutFormDesktop = (
  props: InspectionPaymentCheckoutFormViewProps
) => {
  const {
    firstName,
    description,
    subtotal,
    taxAmount,
    taxPercent,
    total,
    showTax,
    emailHandleBlur,
    emailHandleChange,
    emailErrorMessage,
    handleButtonClick,
    paymentErrorMessage,
    paymentLoading,
    emailInputProps,
    emailSx,
    reaName,
  } = props
  return (
    <Box
      sx={{ backgroundColor: 'trustBlue.main' }}
      padding="48px 160px"
      display="flex"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" gap="32px">
        <Typography variant="h2">{firstName}&apos;s Inspection</Typography>
        <Box display="flex" flexDirection="row" gap="32px">
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              padding="32px"
              borderRadius="16px"
              maxWidth="736px"
              sx={{ backgroundColor: 'white.main' }}
            >
              <Box display="flex" flexDirection="column" gap="12px">
                <Typography variant="cta">
                  Flock Comprehensive Inspection
                </Typography>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography variant="p2">{description}</Typography>
                  <Typography variant="p2">
                    After payment, a billing confirmation will be sent via
                    email, and {reaName} will reach out to schedule your
                    inspection(s) within 24 hours.
                  </Typography>
                </Box>
              </Box>
              <Box
                component="img"
                width="100%"
                src={InspectionWorkerGraphic}
                sx={{ boxShadow: 8 }}
              />
            </Box>
          </Box>
          <form>
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              minWidth="250px"
            >
              <Box width="100%">
                <FormattedTextField
                  fullWidth
                  format="email"
                  label="Email address"
                  placeholder="example@gmail.com"
                  onBlur={emailHandleBlur}
                  onChange={emailHandleChange}
                  helperText={emailErrorMessage}
                  error={!!emailErrorMessage}
                  value={undefined}
                  variant="standard"
                  InputProps={emailInputProps}
                  sx={emailSx}
                />
              </Box>
              <AddressElement options={{ mode: 'billing' }} />
              <PaymentElement />
              <Box display="flex" flexDirection="column" gap="8px">
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="p2">Inspection Cost</Typography>
                  <Typography variant="p2">{subtotal}</Typography>
                </Box>
                {showTax && (
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="p2">
                      Tax {`(${taxPercent})`}
                    </Typography>
                    <Typography variant="p2">{taxAmount}</Typography>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="p1">Total</Typography>
                  <Typography variant="p1">{total}</Typography>
                </Box>
              </Box>
              <Box width="100%">
                <Button
                  fullWidth
                  onClick={handleButtonClick}
                  disabled={paymentLoading}
                >
                  {paymentLoading ? 'Processing...' : 'Pay'}
                </Button>
                <Typography variant="body2" color="#df1b41">
                  {paymentErrorMessage}
                </Typography>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

export default InspectionPaymentCheckoutFormDesktop

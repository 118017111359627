import React from 'react'
import { Box, CircularProgress, Typography, Button, Link } from '@mui/material'
import { RightArrowFilledSecondaryIcon } from '@flock/shared-ui'
import { CheckoutProvider } from '@stripe/react-stripe-js'

import { InspectionPaymentCheckoutPageProps } from './inspectionPaymentCheckoutPageTypes'
import useInspectionPaymentCheckoutPage from './useInspectionPaymentCheckoutPage'
import InspectionPaymentCheckoutForm from './InspectionPaymentCheckoutForm/InspectionPaymentCheckoutForm'

const InspectionPaymentCheckoutPage = (
  props: InspectionPaymentCheckoutPageProps
) => {
  const { accountId } = props
  const {
    loading,
    showCompletion,
    stripePromise,
    clientSecret,
    isTabletOrMobile,
    reaName,
    reaPhoneNumber,
    reaEmail,
    shouldShowReturnToInspectionPaymentsButton,
  } = useInspectionPaymentCheckoutPage(props)
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        padding="48px"
        sx={{
          height: {
            xs: '400px',
            md: '600px',
            lg: '800px',
          },
          backgroundColor: 'trustBlue.main',
        }}
      >
        <CircularProgress size="16vh" />
      </Box>
    )
  }
  if (showCompletion) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: {
            xs: '42px 32px',
            md: '32px 96px',
            lg: '76px 160px',
          },
          backgroundColor: 'trustBlue.main',
        }}
      >
        <Box
          padding="24px"
          borderRadius="16px"
          display="flex"
          flexDirection="column"
          gap="24px"
          sx={{ backgroundColor: 'green0.main', boxShadow: 8 }}
        >
          <Box display="flex" flexDirection="column" gap="12px">
            <Box>
              <Typography variant="cta">Your payment is complete!</Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="p1">
                Your payment has been processed and a billing confirmation will
                be sent to your email.
              </Typography>
              <Typography variant="p1">
                {reaName} (<Link href={`mailto:${reaEmail}`}>{reaEmail}</Link> |{' '}
                <Link href={`tel:${reaPhoneNumber || '(720) 703-9992'}`}>
                  {reaPhoneNumber || '(720) 703-9992'}
                </Link>
                ) will reach out to schedule your inspection soon.
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={isTabletOrMobile ? 'column' : 'row'}
            justifyContent={isTabletOrMobile ? 'flex-start' : 'space-between'}
            gap={isTabletOrMobile ? '16px' : ''}
            width="100%"
          >
            <Box>
              <Button
                onClick={() => {
                  window.location.href = `/account-estimate/${accountId}`
                }}
              >
                <Box display="flex" gap="8px" alignItems="center">
                  <Typography>Go to Valuation Page</Typography>
                  <Box
                    sx={{
                      minWidth: '24px',
                      minHeight: '24px',
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    <RightArrowFilledSecondaryIcon width="24px" height="24px" />
                  </Box>
                </Box>
              </Button>
            </Box>
            {shouldShowReturnToInspectionPaymentsButton && (
              <Box>
                <Button
                  onClick={() => {
                    window.location.href = `/${accountId}/inspection-payments`
                  }}
                  sx={{
                    backgroundColor: 'white.main',
                    color: 'moneyGreen.main',
                    opacity: 0.5,
                  }}
                >
                  <Box display="flex" gap="8px" alignItems="center">
                    <Typography>
                      Return to List of Inspection Payments
                    </Typography>
                    <Box
                      sx={{
                        minWidth: '24px',
                        minHeight: '24px',
                      }}
                      display="flex"
                      alignItems="center"
                    >
                      <RightArrowFilledSecondaryIcon
                        width="24px"
                        height="24px"
                      />
                    </Box>
                  </Box>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }
  if (clientSecret) {
    return (
      <CheckoutProvider
        stripe={stripePromise}
        options={{
          clientSecret,
          elementsOptions: {
            fonts: [
              {
                cssSrc:
                  'https://fonts.googleapis.com/css2?family=Outfit&display=swap',
              },
            ],
            appearance: { variables: { fontFamily: 'Outfit' } },
            loader: 'auto',
          },
        }}
      >
        <InspectionPaymentCheckoutForm accountId={accountId} />
      </CheckoutProvider>
    )
  } else {
    return null
  }
}

export default InspectionPaymentCheckoutPage

import React from 'react'
import useInspectionPaymentLineItem from './useInspectionPaymentLineItem'
import { InspectionPaymentLineItemProps } from './inspectionPaymentLineItemTypes'
import InspectionPaymentLineItemDesktop from './InspectionPaymentLineItemDesktop'
import InspectionPaymentLineItemTablet from './InspectionPaymentLineItemTablet'
import InspectionPaymentLineItemMobile from './InspectionPaymentLineItemMobile'

const InspectionPaymentLineItem = (props: InspectionPaymentLineItemProps) => {
  const { isMobile, isTablet, ...presentationalProps } =
    useInspectionPaymentLineItem(props)
  if (isMobile) {
    return <InspectionPaymentLineItemMobile {...presentationalProps} />
  } else if (isTablet) {
    return <InspectionPaymentLineItemTablet {...presentationalProps} />
  }
  return <InspectionPaymentLineItemDesktop {...presentationalProps} />
}

export default InspectionPaymentLineItem

import React from 'react'

import InspectionPaymentCheckoutFormDesktop from './InspectionPaymentCheckoutFormDesktop'
import InspectionPaymentCheckoutFormMobile from './InspectionPaymentCheckoutFormMobile'
import InspectionPaymentCheckoutFormTablet from './InspectionPaymentCheckoutFormTablet'
import useInspectionPaymentCheckoutForm from './useInspectionPaymentCheckoutForm'
import { InspectionPaymentCheckoutFormProps } from './inspectionPaymentCheckoutFormTypes'

const InspectionPaymentCheckoutForm = (
  props: InspectionPaymentCheckoutFormProps
) => {
  const { isMobile, isTablet, ...presentationalProps } =
    useInspectionPaymentCheckoutForm(props)

  if (isMobile) {
    return <InspectionPaymentCheckoutFormMobile {...presentationalProps} />
  } else if (isTablet) {
    return <InspectionPaymentCheckoutFormTablet {...presentationalProps} />
  }

  return <InspectionPaymentCheckoutFormDesktop {...presentationalProps} />
}

export default InspectionPaymentCheckoutForm

import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import {
  CircleCheckedIcon,
  RightArrowFilledSecondaryIcon,
} from '@flock/shared-ui'
import {
  InspectionPaymentLineItemViewProps,
  InspectionPaymentLineItemStatus,
} from './inspectionPaymentLineItemTypes'

const InspectionPaymentLineItemDesktop = (
  props: InspectionPaymentLineItemViewProps
) => {
  const { amount, description, status, onClick } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        padding: '24px',
        borderRadius: '16px',
        backgroundColor: 'white.main',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="16px"
      >
        <Box display="flex" flexDirection="column" gap="8px">
          <Box>
            <Typography variant="cta">
              Flock Comprehensive Inspection
            </Typography>
          </Box>
          <Box>
            <Typography variant="p2">{description}</Typography>
          </Box>
          <Box>
            <Typography variant="c1" color="moneyGreen.main">
              {amount}
            </Typography>
          </Box>
        </Box>
        <Box minWidth="225px">
          {status === InspectionPaymentLineItemStatus.COMPLETE ? (
            <Button variant="secondary" disabled>
              <Box display="flex" gap="8px" alignItems="center">
                <Box
                  sx={{
                    minWidth: '24px',
                    minHeight: '24px',
                  }}
                >
                  <CircleCheckedIcon width="24px" height="24px" />
                </Box>

                <Typography variant="cta2" sx={{ color: 'moneyGreen.main' }}>
                  Complete
                </Typography>
              </Box>
            </Button>
          ) : (
            <Button onClick={onClick}>
              <Box display="flex" gap="8px" alignItems="center">
                <Typography variant="cta2">Go to Payment</Typography>
                <Box sx={{ minWidth: '24px', minHeight: '24px' }}>
                  <RightArrowFilledSecondaryIcon width="24px" height="24px" />
                </Box>
              </Box>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default InspectionPaymentLineItemDesktop

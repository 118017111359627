import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/gatsby'
import { parseSeverityFromGqlErrors } from '@flock/utils'
// @ts-ignore
import { Severity } from '@flock/utils/src/__generated__/protobuf/ts/errors/errors'

type ConfiguredApolloProviderProps = {
  children: React.ReactNode
}

const CriticalOperationNames = [
  'GetLead',
  'CreateLead',
  'UpdateLead',
  'LandingGetSalesforceAccount',
]

const RetryOperationNames = [
  'GetLead',
  'GetValuation',
  'GetLeadClosingSchedule',
  'PropertyAssets',
  'LandingGetSalesforceAccount',
]

const apolloLinks = []

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (_, operation) =>
      RetryOperationNames.some((retryName) =>
        operation.operationName.includes(retryName)
      ),
  },
})

apolloLinks.push(retryLink)

const errorLink = onError((errResult) => {
  const { operation, graphQLErrors, networkError } = errResult
  const context = operation.getContext()
  const traceId = context.response?.headers?.get('X-Amzn-Trace-Id')
  const ip =
    context.response?.headers?.get('X-Forwarded-For') || 'IP not available'
  const critical = CriticalOperationNames.some((criticalName) =>
    operation.operationName.includes(criticalName)
  ).toString()

  const severity = parseSeverityFromGqlErrors(graphQLErrors)
  if (severity !== Severity.SEVERITY_IGNORE) {
    const error = new Error(`GQL Operation failed: ${operation.operationName}`)
    Sentry.captureException(error, {
      fingerprint: ['gql-error', operation.operationName, error.message],
      tags: {
        traceId,
        critical,
        operationName: operation.operationName,
      },
      extra: {
        ip,
        variables: JSON.stringify(operation.variables, null, 2),
        graphQLErrors: JSON.stringify(graphQLErrors, null, 2),
        networkError: JSON.stringify(networkError, null, 2),
      },
    })
  }
})
apolloLinks.push(errorLink)

const uploadLink = createUploadLink({ uri: process.env.GATSBY_APOLLO_URL })
apolloLinks.push(uploadLink)

export const client = new ApolloClient({
  link: from(apolloLinks as ApolloLink[]),
  cache: new InMemoryCache(),
})

const ConfiguredApolloProvider = ({
  children,
}: ConfiguredApolloProviderProps) => (
  <>
    <ApolloProvider client={client}>{children}</ApolloProvider>
  </>
)

export default ConfiguredApolloProvider

import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  LandingGetInspectionPaymentsDocument,
  InspectionPaymentsGetSalesforceAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'

// import { Core_GetSalesforceInspectionPaymentsRequestInput } from '@flock/flock-gql-server/src/__generated__/graphql'
import { InspectionPaymentsPageProps } from './inspectionPaymentsPageTypes'

export const GET_INSPECTION_PAYMENTS = gql`
  query LandingGetInspectionPayments(
    $input: Core_GetSalesforceInspectionPaymentsRequestInput!
  ) {
    getSalesforceInspectionPayments(input: $input) {
      inspectionPayments {
        amount
        description
        id
        status
      }
    }
  }
`

export const GET_SALESFORCE_ACCOUNT = gql`
  query InspectionPaymentsGetSalesforceAccount(
    $input: Core_GetSalesforceAccountRequestInput!
  ) {
    getSalesforceAccount(input: $input) {
      salesforceAccount {
        fullName
        valuationPageNameOverride
      }
    }
  }
`

const useInspectionPaymentsPage = (props: InspectionPaymentsPageProps) => {
  const { accountId } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(true)

  const goToInspectionPayment = (paymentId: string) => () =>
    navigate(`/${accountId}/inspection-payments/${paymentId}`)

  const { data: inspectionPayments, error } = useQuery(
    LandingGetInspectionPaymentsDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
      onCompleted: (data) => {
        const payments =
          data.getSalesforceInspectionPayments?.inspectionPayments
        if (payments?.length === 1 && payments[0].status === 'Not Complete') {
          goToInspectionPayment(payments[0].id)()
        } else {
          setLoading(false)
        }
      },
      onError: () => {
        setLoading(false)
      },
    }
  )

  const { data: accountData } = useQuery(
    InspectionPaymentsGetSalesforceAccountDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
    }
  )

  let firstName =
    accountData?.getSalesforceAccount?.salesforceAccount?.fullName?.split(
      ' '
    )[0] || ''
  if (
    accountData?.getSalesforceAccount?.salesforceAccount
      ?.valuationPageNameOverride
  ) {
    firstName =
      accountData?.getSalesforceAccount?.salesforceAccount
        ?.valuationPageNameOverride
  }

  const completedInspectionPayments =
    inspectionPayments?.getSalesforceInspectionPayments?.inspectionPayments.filter(
      (payment) => payment.status === 'Complete'
    ) || []

  const notCompletedInspectionPayments =
    inspectionPayments?.getSalesforceInspectionPayments?.inspectionPayments.filter(
      (payment) => payment.status === 'Not Complete'
    ) || []

  const navigateBackToAccountPage = () => {
    window.location.href = `/account-estimate/${accountId}`
  }

  return {
    completedInspectionPayments,
    notCompletedInspectionPayments,
    loading,
    error,
    isMobile,
    isTablet,
    firstName,
    notCompletedInspectionPaymentsOnclick: goToInspectionPayment,
    navigateBackToAccountPage,
  }
}

export default useInspectionPaymentsPage

import { TrackedLink } from '@flock/shared-ui'
import {
  Box,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import FadeIn from 'react-fade-in'

export type ArticleCardProps = {
  title: string
  description: string
  publishedAt: string
  category?: {
    name: string
  }
  slug: string
  delay?: number
  imageUrl?: string
}

const ArticleCard = (props: ArticleCardProps) => {
  const { title, description, category, publishedAt, slug, delay, imageUrl } =
    props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const localeDate = new Date(publishedAt)

  return (
    <Grid item xs={12} sm={4}>
      <TrackedLink
        sx={{ color: '#2B2E31!important' }}
        to={`/blog/${slug}`}
        target="_blank"
      >
        <FadeIn delay={delay}>
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            sx={{
              '& > .gatsby-image-wrapper': {
                opacity: 0.9,
                transition: 'opacity 1s ease',

                '& > [data-main-image]': {
                  transition: 'transform 0.75s ease',
                  transform: 'scale(1.0)',
                },
              },

              '&:hover': {
                '& > .gatsby-image-wrapper': {
                  opacity: 1,

                  '& > [data-main-image]': {
                    transform: 'scale(1.1)',
                  },
                },
              },
            }}
          >
            <Box
              component="img"
              src={imageUrl}
              width="100%"
              height={isTablet ? '118px' : '208px'}
              sx={{
                objectFit: 'cover', // This ensures the image covers the specified area without stretching
                objectPosition: 'top', // This aligns the image to the top so the cropping happens from the top down
              }}
            />
            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" flexDirection="column" gap="4px">
                <Typography variant="h4">{title}</Typography>
                <Typography variant="p2">
                  {localeDate?.toLocaleDateString()}
                </Typography>
              </Box>

              {/* @ts-ignore */}
              <Typography
                variant="p2"
                sx={{
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                }}
              >
                {description}
              </Typography>
              {category && (
                <Box>
                  <Chip label={category?.name?.toUpperCase()} />
                </Box>
              )}
            </Box>
          </Box>
        </FadeIn>
      </TrackedLink>
    </Grid>
  )
}

ArticleCard.defaultProps = {
  delay: 0,
}

export default ArticleCard

import React from 'react'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import { useRecordPageDuration } from '../components/utils'
import WhatIsFlockComponent from '../components/WhatIsFlockComponent/WhatIsFlockComponent'

const pageTitle = 'Flock | What is Flock'
const pageDescription = `Keep the benefits of owning a rental property and leave all of the responsibilities to us.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const WhatIsFlock = () => {
  useRecordPageDuration()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="what-is-flock"
      headerBackground="trustBlue.main"
    >
      <WhatIsFlockComponent />
    </PageWrapper>
  )
}

export default WhatIsFlock

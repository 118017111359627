import React from 'react'
import WhatIsFlockComponentDesktop from './WhatIsFlockComponentDesktop'
import WhatIsFlockComponentTablet from './WhatIsFlockComponentTablet'
import WhatIsFlockComponentMobile from './WhatIsFlockComponentMobile'
import useWhatIsFlockComponent from './useWhatIsFlockComponent'
import { WhatIsFlockComponentProps } from './whatIsFlockComponentTypes'

const WhatIsFlockComponent = (props: WhatIsFlockComponentProps) => {
  const presentationalProps = useWhatIsFlockComponent(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <WhatIsFlockComponentMobile />
  }
  if (isTablet) {
    return <WhatIsFlockComponentTablet />
  }
  return <WhatIsFlockComponentDesktop />
}

export default WhatIsFlockComponent

import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const RightArrowFilledSecondaryIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle
        cx="24"
        cy="24"
        r="17"
        transform="rotate(-180 24 24)"
        fill={fillStyle || 'white'}
        fillOpacity="0.5"
      />
      <path
        d="M25.1633 32L23.8163 30.6429L29.3615 24.9821L15 24.9821L15 23.0179L29.3615 23.0179L23.8163 17.375L25.1633 16L33 24L25.1633 32Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default RightArrowFilledSecondaryIcon

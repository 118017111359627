import React from 'react'
import PageWrapper from '../../../components/SharedComponents/PageWrapper'
import InspectionPaymentsPage from '../../../components/InspectionPaymentComponents/InspectionPaymentsPage/InspectionPaymentsPage'

type AllInspectionPaymentsProps = {
  params: {
    account: string
  }
}

const AllInspectionPayments = (props: AllInspectionPaymentsProps) => {
  const { params } = props
  const { account: accountId } = params
  return (
    <PageWrapper
      title="Flock Homes | Inspection Payments"
      trackingName="inspection-payments-page"
      headerBackground="trustBlue.main"
      useSquareTheme
    >
      <InspectionPaymentsPage accountId={accountId} />
    </PageWrapper>
  )
}

export default AllInspectionPayments

import React from 'react'
import SectionLayout from '../SharedComponents/SectionLayout'
import ArticleCard, { ArticleCardProps } from './ArticleCard'

type ArticlesGridProps = {
  articles: ArticleCardProps[]
}

const ArticlesGrid = (props: ArticlesGridProps) => {
  const { articles } = props
  return (
    <SectionLayout name="articles" backgroundColor="trustBlue.main">
      {articles
        .sort(
          (a, b) =>
            new Date(b.publishedAt).getTime() -
            new Date(a.publishedAt).getTime()
        )
        .map((article, idx) => (
          <ArticleCard key={article.slug} {...article} delay={idx * 100} />
        ))}
    </SectionLayout>
  )
}

export default ArticlesGrid

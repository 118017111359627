import React from 'react'
import { SnackbarProvider } from '@flock/shared-ui'
import PageWrapper from '../../../components/SharedComponents/PageWrapper'
import InspectionPaymentCheckoutPage from '../../../components/InspectionPaymentComponents/InspectionPaymentCheckoutPage/InspectionPaymentCheckoutPage'

type PaymentCheckoutPageProps = {
  params: {
    account: string
    payment: string
  }
}

const PaymentCheckoutPage = (props: PaymentCheckoutPageProps) => {
  const { params } = props
  const { account: accountId, payment: paymentId } = params

  return (
    <PageWrapper
      title="Flock Homes | Payment Checkout"
      trackingName="payment-checkout-page"
      headerBackground="trustBlue.main"
      useSquareTheme
    >
      <SnackbarProvider>
        <InspectionPaymentCheckoutPage
          accountId={accountId}
          paymentId={paymentId}
        />
      </SnackbarProvider>
    </PageWrapper>
  )
}

export default PaymentCheckoutPage

import { useTheme, useMediaQuery } from '@mui/material'
import { formatDollarsAndCents } from '@flock/utils'
import { InspectionPaymentLineItemProps } from './inspectionPaymentLineItemTypes'

const useInspectionPaymentLineItem = (
  props: InspectionPaymentLineItemProps
) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const amount = formatDollarsAndCents(props.amount)

  return {
    isMobile,
    isTablet,
    ...props,
    amount,
  }
}

export default useInspectionPaymentLineItem

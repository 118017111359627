import { useCallback, useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  CreateInspectionPaymentCheckoutSessionDocument,
  CompleteInspectionPaymentCheckoutSessionDocument,
  GetSalesforceInspectionPaymentDocument,
  InspectionPaymentCheckoutPageGetInspectionPaymentsDocument,
  InspectionPaymentCheckoutPageGetSalesforceAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { InspectionPaymentCheckoutPageProps } from './inspectionPaymentCheckoutPageTypes'
import { STRIPE_PUBLIC_KEY } from '../../../constants'

export const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateInspectionPaymentCheckoutSession(
    $input: Core_CreateInspectionPaymentCheckoutSessionRequestInput!
  ) {
    createInspectionPaymentCheckoutSession(input: $input) {
      clientSecret
    }
  }
`

export const COMPLETE_CHECKOUT_SESSION = gql`
  mutation CompleteInspectionPaymentCheckoutSession(
    $input: Core_CompleteInspectionPaymentCheckoutSessionRequestInput!
  ) {
    completeInspectionPaymentCheckoutSession(input: $input) {
      status
    }
  }
`

export const GET_INSPECTION_PAYMENT = gql`
  query GetSalesforceInspectionPayment(
    $input: Core_GetSalesforceInspectionPaymentRequestInput!
  ) {
    getSalesforceInspectionPayment(input: $input) {
      inspectionPayment {
        id
        amount
        status
        description
      }
    }
  }
`

export const GET_INSPECTION_PAYMENTS = gql`
  query InspectionPaymentCheckoutPageGetInspectionPayments(
    $input: Core_GetSalesforceInspectionPaymentsRequestInput!
  ) {
    getSalesforceInspectionPayments(input: $input) {
      inspectionPayments {
        amount
        description
        id
        status
      }
    }
  }
`

export const GET_SALESFORCE_ACCOUNT = gql`
  query InspectionPaymentCheckoutPageGetSalesforceAccount(
    $input: Core_GetSalesforceAccountRequestInput!
  ) {
    getSalesforceAccount(input: $input) {
      salesforceAccount {
        owner {
          firstName
          email
          phoneNumber
        }
      }
    }
  }
`

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY as string, {
  betas: ['custom_checkout_beta_5'],
})

const useInspectionPaymentCheckoutPage = (
  props: InspectionPaymentCheckoutPageProps
) => {
  const { accountId, paymentId } = props
  // since we use same URL for showing the initial checkout and the return URL, we want to not render the checkout
  // until we know the payment is not complete.
  const [loading, setLoading] = useState(true)
  const [showCompletion, setShowCompletion] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [
    shouldShowReturnToInspectionPaymentsButton,
    setShouldShowReturnToInspectionPaymentsButton,
  ] = useState(false)

  let urlParams = new URLSearchParams()
  if (typeof window !== 'undefined') {
    urlParams = new URLSearchParams(window.location.search)
  }
  const sessionId = urlParams.get('session_id')

  const { notify } = useSnackbar()

  const [createInspectionPaymentCheckoutSession] = useMutation(
    CreateInspectionPaymentCheckoutSessionDocument
  )

  const [completeInspectionPaymentCheckoutSession] = useMutation(
    CompleteInspectionPaymentCheckoutSessionDocument
  )

  const { data: inspectionPayments } = useQuery(
    InspectionPaymentCheckoutPageGetInspectionPaymentsDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
    }
  )

  const { data: inspectionPayment } = useQuery(
    GetSalesforceInspectionPaymentDocument,
    {
      variables: {
        input: {
          paymentId,
        },
      },
    }
  )

  const { data: salesforceAccount } = useQuery(
    InspectionPaymentCheckoutPageGetSalesforceAccountDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
    }
  )

  // we only want to show the "return to inspection payments" button if there is more than one inspection payment
  useEffect(() => {
    if (
      inspectionPayments?.getSalesforceInspectionPayments?.inspectionPayments &&
      inspectionPayments.getSalesforceInspectionPayments.inspectionPayments
        ?.length > 1
    ) {
      setShouldShowReturnToInspectionPaymentsButton(true)
    }
  }, [inspectionPayments])

  const fetchAndSetClientSecret = useCallback(async () => {
    const { data } = await createInspectionPaymentCheckoutSession({
      variables: {
        input: {
          accountId,
          paymentId,
        },
      },
    })

    setClientSecret(
      data?.createInspectionPaymentCheckoutSession?.clientSecret || ''
    )
  }, [
    accountId,
    createInspectionPaymentCheckoutSession,
    paymentId,
    setClientSecret,
  ])

  useEffect(() => {
    const initialPageLoadActions = async () => {
      if (inspectionPayment)
        if (
          inspectionPayment.getSalesforceInspectionPayment?.inspectionPayment
            ?.status === 'Complete'
        ) {
          // salesforce inspection payment is either Complete or Not Complete
          setShowCompletion(true)
          setLoading(false)
        } else if (sessionId) {
          // else if not complete, and there is a session id, try to complete it.
          const completeCheckout = async () => {
            const result = await completeInspectionPaymentCheckoutSession({
              variables: {
                input: {
                  paymentId,
                  accountId,
                  sessionId,
                },
              },
            })
            setLoading(false)
            // stripe session status is complete", "expired", "open"
            if (
              result?.data?.completeInspectionPaymentCheckoutSession?.status ===
              'complete'
            ) {
              setShowCompletion(true)
            } else {
              notify('Payment was not successful, please try again.')
            }
          }
          completeCheckout()
        } else {
          // if no session id, is new transaction, so can just render the checkout.
          await fetchAndSetClientSecret()
          setLoading(false)
        }
    }
    initialPageLoadActions()
  }, [
    inspectionPayment,
    accountId,
    completeInspectionPaymentCheckoutSession,
    notify,
    paymentId,
    sessionId,
    fetchAndSetClientSecret,
  ])

  return {
    clientSecret,
    stripePromise,
    loading,
    showCompletion,
    accountId,
    shouldShowReturnToInspectionPaymentsButton,
    isTabletOrMobile,
    reaName:
      salesforceAccount?.getSalesforceAccount?.salesforceAccount?.owner
        ?.firstName || '',
    reaEmail:
      salesforceAccount?.getSalesforceAccount?.salesforceAccount?.owner
        ?.email || '',
    reaPhoneNumber:
      salesforceAccount?.getSalesforceAccount?.salesforceAccount?.owner
        ?.phoneNumber || '',
  }
}

export default useInspectionPaymentCheckoutPage

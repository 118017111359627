import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { RightArrowFilledSecondaryIcon } from '@flock/shared-ui'

import { InspectionPaymentsPageViewProps } from './inspectionPaymentsPageTypes'
import InspectionPaymentLineItem from './InspectionPaymentLineItem/InspectionPaymentLineItem'
import { InspectionPaymentLineItemStatus } from './InspectionPaymentLineItem/inspectionPaymentLineItemTypes'

const InspectionPaymentsPageDesktop = (
  props: InspectionPaymentsPageViewProps
) => {
  const {
    completedInspectionPayments,
    notCompletedInspectionPayments,
    notCompletedInspectionPaymentsOnclick,
    firstName,
    navigateBackToAccountPage,
  } = props
  return (
    <Box padding="48px 160px" sx={{ backgroundColor: 'trustBlue.main' }}>
      <Box display="flex" flexDirection="column" gap="32px">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h2">{firstName}&apos;s Inspection</Typography>
          <Button onClick={navigateBackToAccountPage}>
            <Box display="flex" gap="8px" alignItems="center">
              <Typography>Go to Valuation Page</Typography>
              <RightArrowFilledSecondaryIcon width="24px" height="24px" />
            </Box>
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px">
          {notCompletedInspectionPayments.map((payment) => (
            <InspectionPaymentLineItem
              key={payment.id}
              amount={payment.amount}
              description={payment.description}
              status={InspectionPaymentLineItemStatus.NOT_COMPLETE}
              onClick={notCompletedInspectionPaymentsOnclick(payment.id)}
            />
          ))}
          {completedInspectionPayments.map((payment) => (
            <InspectionPaymentLineItem
              key={payment.id}
              amount={payment.amount}
              description={payment.description}
              status={InspectionPaymentLineItemStatus.COMPLETE}
              onClick={() => {}}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default InspectionPaymentsPageDesktop

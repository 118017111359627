import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(
      `https://docs.google.com/forms/d/e/1FAIpQLScfq8uQMIUBgECHixHPWY9SoOi1SjizNZ2l-LyL7GCO6TLfag/viewform`
    )
  }, [])
  return null
}

import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {
  flockTheme,
  LibraryThemeProvider,
  SnackbarProvider,
  TrackedLink,
  useTracking,
} from '@flock/shared-ui'
import { UserEventType } from '@flock/utils'
import * as Sentry from '@sentry/gatsby'

import { useRecordPageDuration } from '../../components/utils'
import SectionLayout from '../../components/SharedComponents/SectionLayout'
import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENTRIES_URL,
} from '../../constants'
import PageWrapper from '../../components/SharedComponents/PageWrapper'
import BlogShare from '../../components/BlogComponents/BlogShare'
import InlineMessageAddress from '../../components/SharedComponents/InlineMessageAddress'
import { WebinarModal } from '../../components/SharedComponents/WebinarModal'
import { PostData } from '../blog'
import ErrorCard from '../../components/SalesforceOfferPageComponents/ErrorCard'

type ContentfulChild =
  | string
  | number
  | boolean
  | React.ReactFragment
  | React.ReactPortal
  | React.ReactElement
  | null
  | undefined

export const Head = (props: BlogPostProps) => {
  const { params } = props
  const { slug } = params

  return (
    <>
      <title>{slug}</title>
      <meta
        title="title"
        property="og:title"
        content={slug?.replace('-', ' ')}
      />
      <meta name="description" property="og:description" content={slug} />
    </>
  )
}

const formatPostData = (response: any) => {
  const item = response?.items[0]
  const mediaAsset = response?.includes?.Asset.filter(
    (media: any) => media?.sys?.id === item?.fields?.image?.sys?.id
  )[0]

  const embeddedAssetIdsToUrls = new Map()
  response?.includes?.Asset.forEach((asset: any) => {
    embeddedAssetIdsToUrls.set(
      asset?.sys?.id,
      `https://${asset?.fields?.file?.url?.replace(/^\/\//, '')}`
    )
  })

  const blogPost: PostData = {
    title: item?.fields?.title,
    description: item?.fields?.description,
    publishedAt: item?.fields?.publishDate,
    slug: item?.fields?.slug,
    imageUrl: `https://${mediaAsset?.fields?.file?.url?.replace(/^\/\//, '')}`,
    blogText: item?.fields?.blogText,
    embeddedAssetIdsToUrls,
  }

  return blogPost
}

type BlogPostProps = {
  params: {
    slug: string
  }
}

const BlogPost = (props: BlogPostProps) => {
  const { params } = props
  const { slug } = params
  const [postData, setPostData] = useState<PostData | null>(null)
  const [pageError, setPageError] = useState(false)

  // Fetch data from API once when the component mounts
  useEffect(() => {
    const onPageLoad = async () => {
      try {
        const urlWithToken = `${CONTENTFUL_ENTRIES_URL}?access_token=${CONTENTFUL_ACCESS_TOKEN}&fields.slug=${slug}&content_type=blogPost`
        const response = await fetch(urlWithToken)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }

        const result = await response.json()
        if (!result?.items?.length) {
          throw new Error('Failed to fetch post data')
        }

        const formattedPostData = formatPostData(result)
        setPostData(formattedPostData)
      } catch (error) {
        // Handle page load error
        setPageError(true)
      }
    }
    onPageLoad()
  }, [setPostData, slug])

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        // eslint-disable-next-line react/destructuring-assignment
        const assetId = node.data.target.sys.id
        const assetUrl = postData?.embeddedAssetIdsToUrls?.get(assetId) || ''

        return <img src={assetUrl} alt="Embedded asset" />
      },
      [INLINES.HYPERLINK]: (node: any, children: ContentfulChild) => (
        <TrackedLink onClick={() => window.open(`${node?.data?.uri}`)}>
          {children}
        </TrackedLink>
      ),
      [BLOCKS.PARAGRAPH]: (_: any, children: ContentfulChild) => (
        <Typography color="gray8.main">{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (_: any, children: ContentfulChild) => (
        <Typography variant="h4" fontWeight="500" color="gray8.main">
          {children}
        </Typography>
      ),
    },
  }

  const { title, imageUrl, publishedAt, blogText, author } = postData || {}

  const publicationDate = publishedAt ? new Date(publishedAt) : new Date()
  const renderedDate = publicationDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { trackPage } = useTracking()

  useEffect(() => {
    if (slug) {
      trackPage(`blog`, {
        category: 'blog',
        slug,
        actionType: UserEventType.PAGE_VISIT,
      })
    }
  }, [slug, trackPage])

  useEffect(() => {
    if (pageError) {
      Sentry.captureException(new Error(`Blog failed to load`), {
        tags: {},
        extra: {
          slug,
        },
      })
    }
  }, [pageError, slug])

  useRecordPageDuration()

  if (pageError) {
    return (
      <PageWrapper
        title={`Flock | ${title}`}
        trackingName="blog"
        headerBackground="trustBlue.main"
      >
        <ThemeProvider theme={flockTheme}>
          <LibraryThemeProvider>
            <Box
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              id="calendlyPopupRoot"
              sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
            >
              <ErrorCard text="Please try refreshing the page or reach out to us below." />
            </Box>
          </LibraryThemeProvider>
        </ThemeProvider>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper
      title={`Flock | ${title}`}
      trackingName="blog"
      headerBackground="trustBlue.main"
    >
      <SnackbarProvider>
        <SectionLayout name="blog-post-header" backgroundColor="trustBlue.main">
          {!isTablet && <Grid item md={2} />}
          <Grid item xs={12} md={8}>
            <Grid item xs={12} display="flex" flexDirection="column" gap="16px">
              <Typography variant="h2">{title}</Typography>
              <Typography>
                Published {renderedDate}{' '}
                {author?.name && `— Written by ${author?.name}`}
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                }}
              />
              <BlogShare slug={slug} />
            </Grid>
            <Grid item xs={12} display="flex">
              <Box component="img" src={imageUrl} width="100%" />
            </Grid>
            {blogText ? (
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
                pb="20px"
                pt="20px"
              >
                {documentToReactComponents(blogText, options)}
              </Grid>
            ) : null}
            <Box>
              <InlineMessageAddress
                text="Landlords, retire and minimize taxes."
                variant="button"
                label="Get Started"
              />
              <Grid item xs={12} textAlign="end" pt="16px">
                <WebinarModal />
              </Grid>
            </Box>
          </Grid>
          {!isMobile && <Grid item sm={2} />}
        </SectionLayout>
      </SnackbarProvider>
    </PageWrapper>
  )
}

export default BlogPost

export enum InspectionPaymentLineItemStatus {
  COMPLETE = 'Complete',
  NOT_COMPLETE = 'Not Complete',
}

export type InspectionPaymentLineItemProps = {
  amount: number
  description: string
  status: InspectionPaymentLineItemStatus
  onClick: () => void
}

export type InspectionPaymentLineItemViewProps = {
  amount: string
  description: string
  status: InspectionPaymentLineItemStatus
  onClick: () => void
}

import { useMediaQuery, useTheme } from '@mui/material'
import {
  WhatIsFlockComponentProps,
  WhatIsFlockComponentPresentationalProps,
} from './whatIsFlockComponentTypes'

const useWhatIsFlockComponent: (
  props: WhatIsFlockComponentProps
) => WhatIsFlockComponentPresentationalProps = (
  props: WhatIsFlockComponentProps
) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    ...props,
    isMobile,
    isTablet,
  }
}

export default useWhatIsFlockComponent

import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(
      `https://docs.google.com/forms/d/e/1FAIpQLScJgu1VKN7A15UFhxKd5QE4E_7ATvxM1KH8NBsHMHo1IajG0g/viewform`
    )
  }, [])
  return null
}

import React from 'react'
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { RightArrowFilledIcon } from '@flock/shared-ui'
import SectionLayout from '../SharedComponents/SectionLayout'
import RyanImage from '../../images/about-us-ryan.webp'
import AriImage from '../../images/about-us-ari.webp'
import LeanneImage from '../../images/about-us-leanne.webp'
import ChadImage from '../../images/about-us-chad.webp'
import AlexImage from '../../images/about-us-alex.webp'
import ColleenImage from '../../images/about-us-colleen.webp'
import NicolaImage from '../../images/about-us-nicola.webp'
import DylanImage from '../../images/about-us-dylan.webp'
import WilliamImage from '../../images/about-us-will.webp'
import JackImage from '../../images/about-us-jack.webp'
import TeamImage from '../../images/flock-offsite-image.webp'
import TeamImageMobile from '../../images/flock-offsite-mobile-image.webp'

type AboutUsTeamProps = {
  name: string
  img: string
  gridSpacing: number
}

const AboutUsTeam = (props: AboutUsTeamProps) => {
  const { name, img: Image, gridSpacing } = props

  return (
    <Grid
      item
      xs={gridSpacing}
      gap="4px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        filter: 'grayscale(1)',
        cursor: 'pointer',
        '&:hover': {
          filter: 'grayscale(0)',
        },
      }}
    >
      <Box component="img" src={Image} width="100%" />
      <Typography
        variant="p2"
        color="gray8.main"
        sx={{ fontWeight: 500, textAlign: 'center' }}
      >
        {name}
      </Typography>
    </Grid>
  )
}

type AboutUsHeadshotProps = {
  name: string
  title: string
  img: string
  gridSpacing: number
  tooltip: string
}

const AboutUsHeadshot = (props: AboutUsHeadshotProps) => {
  const { name, title, img: Image, gridSpacing, tooltip } = props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid
      item
      xs={gridSpacing}
      gap="4px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        filter: 'grayscale(1)',
        cursor: 'pointer',
        '&:hover': {
          filter: 'grayscale(0)',
        },
      }}
    >
      <Tooltip
        title={tooltip}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={6000}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'gray1.main',
              maxWidth: isTablet ? '200px' : '304px',
              borderRadius: '9px',
              boxShadow:
                '0px 8px 32px 0px rgba(91, 115, 139, 0.24), 0px 0px 2px 0px rgba(91, 115, 139, 0.16)',
              color: 'gray8.main',
              fontFamily: 'Outfit',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px' /* 142.857% */,
              '& .MuiTooltip-arrow': {
                color: 'gray1.main',
              },
            },
          },
        }}
      >
        <Box component="img" src={Image} width="100%" />
      </Tooltip>
      <Typography
        variant="p2"
        color="gray8.main"
        sx={{ fontWeight: 500, textAlign: 'center' }}
      >
        {name}
      </Typography>
      <Typography variant="p3" color="gray8.main">
        {title}
      </Typography>
    </Grid>
  )
}

const AboutTeamSection = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let aboutHeadshotSpacing = 2
  if (isTablet) {
    aboutHeadshotSpacing = 4
  }
  if (isMobile) {
    aboutHeadshotSpacing = 6
  }

  let aboutTeamSpacing = 4
  if (isTablet) {
    aboutTeamSpacing = 8
  }
  if (isMobile) {
    aboutTeamSpacing = 12
  }

  return (
    <SectionLayout
      name="about-story"
      backgroundColor="trustBlue.main"
      verticalPadding="64px"
    >
      <Grid item xs={12}>
        <Typography variant="h2" color="gray8.main">
          Our leadership.
        </Typography>
      </Grid>
      <Grid item xs={isTablet ? 12 : 6}>
        <Typography variant="p1" color="gray8.main">
          Flock’s team encompasses decades of experience working at the largest
          real estate investment institutions in the world, coming together to
          better the lives of individual real estate owners.
        </Typography>
      </Grid>
      <Grid item xs={isTablet ? 12 : 6}>
        <Typography variant="h3" color="moneyGreen.main" display="inline">
          &quot;Transparency. Integrity. Humanity. You can feel the pride the
          Flock team takes in what it does and is building.&quot; &nbsp;
        </Typography>
        <Box
          component={isMobile ? 'div' : 'span'}
          paddingTop={isMobile ? '8px' : '0px'}
        >
          <Typography variant="h3" color="moneyGreen.main" display="inline">
            —Lucy K.&nbsp;
          </Typography>
          <Typography variant="c1" color="moneyGreen.main">
            JOINED FLOCK IN 2023
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Box display="flex" gap="4px">
          {isTablet ? (
            <Typography variant="c1" color="moneyGreen.main">
              TAP IMAGE TO VIEW BIO
            </Typography>
          ) : (
            <Typography variant="c1" color="moneyGreen.main">
              HOVER OVER IMAGE TO VIEW BIO
            </Typography>
          )}
          <Box width="20px" height="20px" sx={{ transform: 'rotate(90deg)' }}>
            <RightArrowFilledIcon width="20px" height="20px" />
          </Box>
        </Box>
      </Grid>
      <AboutUsHeadshot
        name="Ari Rubin"
        title="Founder & CEO"
        img={AriImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Ari started Flock after experiencing the challenges of being a landlord himself. Prior to Flock, he worked as a hedge fund manager at Ibex Investors, managing $150M+ in assets, and served in the Israel Defense Forces. Ari attended Stanford’s Graduate School of Business and received his AB from Harvard University."
      />
      <AboutUsHeadshot
        name="Ryan Illsley"
        title="Investments"
        img={RyanImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Ryan has overseen the acquisition of over 40,000 homes and leads the selection of every asset that joins Flock’s fund. Ryan previously worked as the VP of Acquisitions at Progress Residential, the largest private owner-operator of single-family rental homes in the country."
      />
      <AboutUsHeadshot
        name="Leanne Dunn"
        title="Legal"
        img={LeanneImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip={`Leanne has 30+ years of legal experience in real estate and investment management and leads Flock's legal and tax strategy. Leanne previously served as Chief Legal Counsel for State Street Global Advisor's Real Estate Investment group. She started her legal career at Simpson Thacher & Bartlett.`}
      />
      <AboutUsHeadshot
        name="Chad Nowakowski"
        title="Operations"
        img={ChadImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Chad has spent nearly a decade structuring and implementing the 721 exchange, including at J.P. Morgan, and leads Flock’s business operations. Chad has an MBA from University of Virginia’s Darden School of Business and a BBA in finance from William and Mary."
      />
      <AboutUsHeadshot
        name="Alex Cettina"
        title="Capital Markets"
        img={AlexImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Alex has nearly a decade of experience in real estate acquisitions and debt & equity capital markets and leads Flock’s capital markets efforts. Alex previously worked at Tishman, a New York-based real estate investment firm. Alex has a BSBA in Finance from Washington University in St. Louis."
      />
      <AboutUsHeadshot
        name="Colleen Monaghan"
        title="Home Operations"
        img={ColleenImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Colleen has managed over 10,000 real estate renovation projects across the Midwest and now leads renovations for all of Flock’s assets. Prior to this, she served as the Renovation Field Manager at Zillow."
      />
      <AboutUsHeadshot
        name="Nicola Montagna"
        title="Chief of Staff"
        img={NicolaImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Nicola has nearly a decade of experience across the private and public financial sectors, including at Apollo Global Management, Uber, and most recently serving as the Special Advisor in the Small Business Administration’s Office of Capital Access. Nicola holds a BBA in Finance from the University of Michigan."
      />
      <AboutUsHeadshot
        name="Dylan Kennedy"
        title="Asset Management"
        img={DylanImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Dylan has spent nearly a decade leading operationally-intensive businesses across real estate and previously served as the COO of Blue Sky, a real estate development and investment company. Dylan holds an MBA from Stanford’s Graduate School of Business.  "
      />
      <AboutUsHeadshot
        name="William Zhuang"
        title="Engineering"
        img={WilliamImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="William has led the development of technology products at the largest real estate and industrial technology firms, including Opendoor and G.E. Digital, and oversees Flock’s technology platform. William holds a BS in Engineering from UC Berkeley."
      />
      <AboutUsHeadshot
        name="Jack Badavas"
        title="Acquisitions"
        img={JackImage}
        gridSpacing={aboutHeadshotSpacing}
        tooltip="Jack has spent his decades-long career holding leadership positions at Segment and Salesforce, where he spearheaded sales and expansion strategies across diverse regions and markets. Jack attended Bentley University and has degrees in both Marketing and Business Law."
      />
      <AboutUsTeam
        name="& the rest of the Flock team!"
        img={isMobile ? TeamImageMobile : TeamImage}
        gridSpacing={aboutTeamSpacing}
      />
    </SectionLayout>
  )
}

export default AboutTeamSection
